import React, { useRef, useState } from 'react'
import '../Styles/Profile.css'
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { UniversalLoader } from '../SmallComponents/SmallUIComponents';
import { useNavigate } from 'react-router';

export default function Profile() {

    const userNameRef = useRef("")
    const auth = getAuth()
    const passwordRef = useRef("")
    const navigate = useNavigate()
    const [Loading, setLoading] = useState(false)
    // agripwn@gmail.com
    // 9983836233


    const checkFeilds = () => {
        if (userNameRef.current.value !== '') {
            if (userNameRef.current.value === 'agripwn@gmail.com') {
                if (passwordRef.current.value !== '') {
                    SignIn()
                } else { passwordRef.current.focus() }
            } else { alert("You're not authorize to login") }
        } else { userNameRef.current.focus() }
    }

    const SignIn = () => {
        setLoading(true)
        signInWithEmailAndPassword(auth, userNameRef.current.value, passwordRef.current.value)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                console.log(user);
                setLoading(false)
                navigate("/admin")
                // ...
            })
            .catch((error) => {
                console.log(error);
                setLoading(false)
                alert("Invalid username or password")
            });
    }


    return (
        <div className="login_Box_wrapper">
            <div className="form">
                <p className="form-title">Sign in to your account</p>
                <div className="input-container">
                    <input type="email" ref={userNameRef} placeholder="Enter username" />
                </div>
                <div className="input-container">
                    <input type="password" ref={passwordRef} placeholder="Enter password" />
                </div>
                <button onClick={checkFeilds} className="submit">
                    Sign in
                </button>
            </div>


            {/* universal Loader on loading */}
            {Loading ? <UniversalLoader /> : ''}
        </div>
    )
}

// U2FsdGVkX1901sJJ2sh4hjlbgQZv6jNPRzQ97rcbn9uRyQXYxwHYIPCb5W+W5Ygs
// U2FsdGVkX18YkxJTJr3fwuAb10+MkYH+GEYq9sf7lW3EURBPewpfvlYGxIs/ibbb