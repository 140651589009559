import React from 'react'
import '../Styles/Drawer.css'
import { useState } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { doc, getDoc } from 'firebase/firestore'
import { db } from '../Firebase'
import { useSelector } from 'react-redux'

export default function Drawer(props) {
    const [IsMenuActive, setIsMenuActive] = useState(false)
    const [DynamicStyle, setDynamicStyle] = useState("translateX(100%)")
    // const [UserDetails, setUserDetails] = useState(null)
    const navigate = useNavigate()
    const UserDetails = useSelector(state => state.UserDetails.UserDetails)
    
    useEffect(() => {
        if (IsMenuActive) {
            setDynamicStyle("translateX(0%)")
        }
        else {
            setDynamicStyle("translateX(100%)")
        }
    }, [IsMenuActive])

    return (
        <div>

            {
                props.isHide ? '' : <div className="buttonContainer" onClick={() => { setIsMenuActive(!IsMenuActive) }}>
                    <div className="button" tabindex="0">
                        {
                            IsMenuActive ? <i className="fas fa-times"></i> : <i className="fas fa-bars"></i>
                        }
                    </div>
                </div>
            }

            <div id="nav-container" style={{ transform: DynamicStyle }}>
                <div id="nav-content">

                    <div className='li' onClick={() => {
                        navigate("/admin")
                        setIsMenuActive(false)
                    }}>Admin</div>

                    <div className="line"></div>

                    <div className='li' onClick={() => {
                        navigate("/")
                        setIsMenuActive(false)
                    }}>Blogs</div>

                    <div className='li' onClick={() => {
                        navigate("/about")
                        setIsMenuActive(false)
                    }}>About</div>

                    <div onClick={() => {
                        navigate('/gallery')
                        setIsMenuActive(false)
                    }} className='li'>Gallery</div>

                    <div className="line"></div>
                    <div className='li' onClick={() => { window.open(UserDetails?.FacebookURL, "_blank", "noreferrer") }} >Facebook</div>
                    <div className='li' onClick={() => { window.open(UserDetails?.TwitterURL, "_blank", "noreferrer") }} >Twitter</div>
                    <div className='li' onClick={() => { window.open(UserDetails?.InstagramURL, "_blank", "noreferrer") }} >Instagram</div>
                    <div className='li' onClick={() => { window.open(UserDetails?.KooURL, "_blank", "noreferrer") }} >Koo</div>
                    <div className='li' onClick={() => { window.open(UserDetails?.YoutubeURL, "_blank", "noreferrer") }} >Youtube</div>
                </div>
            </div>
            <div className="bg" style={{ display: IsMenuActive ? "block" : "none" }}></div>
        </div>
    )
}
