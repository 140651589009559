import React, { useState } from 'react'
import QuestionTile from '../SmallComponents/QuestionTile'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { setBlogs, setLastblog } from '../Redux/Slices/BlogsSlice';
import LandingPage from '../SmallComponents/LandingPage';
import { db } from '../Firebase';
import { collection, getDocs, query, orderBy, startAfter, limit } from 'firebase/firestore';
import Loader from '../SmallComponents/Loader';
import Drawer from './Drawer';


export default function Homepage() {

    const dispatch = useDispatch();
    const Blogs = useSelector(state => state.Blogs)
    const LastBlog = useSelector(state => state.Blogs.LastBlog)
    const Loading = useSelector(state => state.Blogs.Loading)
    const [LoadingMoreBlogs, setLoadingMoreBlogs] = useState(false)
    const [isReachedBottom, setisReachedBottom] = useState(false)

    const FetchNextBlogs = async () => {
        setLoadingMoreBlogs(true)
        let newBlogs = [];
        const newBlogsQuery = query(collection(db, 'root'), orderBy("createdAt", "desc"), limit(3), startAfter(LastBlog))
        const querySnapshot = await getDocs(newBlogsQuery);
        querySnapshot.forEach((doc) => {
            newBlogs.push({
                BlogTitle: doc.data().title,
                BlogImage: doc.data().attachedImage,
                BlogContent: doc.data().content,
                createdAt: doc.data().createdAt,
                BlogID: doc.id,
                BlogViewCount: doc.data().viewCount
            })
        })
        dispatch(setBlogs(newBlogs))
        const last = querySnapshot.docs[querySnapshot.docs.length - 1];
        dispatch(setLastblog(last));
        setisReachedBottom(false)
        setTimeout(() => {
            setLoadingMoreBlogs(false)
        }, 1000);
    }

    useEffect(() => { if (isReachedBottom) { FetchNextBlogs() } }, [isReachedBottom])
    useEffect(() => {
        function handleScroll() {
            if ((window.innerHeight + Math.round(window.scrollY)) >= document.body.offsetHeight) {
                setisReachedBottom(true)
                console.log("bottom reached");
            }
        }
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
            {/* <Navbar /> */}
            <LandingPage isBlog={false} />
            <div className="container hompageContainer">
                {/* questions container */}
                <div className="question-container" id='scrollContainer'>
                    {Blogs.FetchedBlogs.length === 0 ? <h6 style={{ marginLeft: '20px' }}>No blogs found</h6> : ''}

                    {/* all questions will fetch here */}
                    {
                        Loading === 'loading' ? <Loader />
                            :
                            Blogs.FetchedBlogs.map((key) => {
                                return <QuestionTile
                                    key={key.BlogID}
                                    questionTitle={key.BlogTitle}
                                    image={key.BlogImage}
                                    content={key.BlogContent}
                                    dateTime={key.createdAt}
                                    id={key.BlogID}
                                    viewCount={key.BlogViewCount}
                                />
                            })
                    }
                    {
                        LoadingMoreBlogs ? <div className='MoreBlogsLoading'>
                            <Loader />
                        </div> : ''
                    }
                    {/* <div className="LoadMore" onClick={FetchNextBlogs}>Load more blogs...</div> */}

                </div>
                {/* related questions */}
                <div className="related-questions">
                    More blogs
                </div>
                <Drawer />
            </div>
        </>
    )
}
