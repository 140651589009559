import React from 'react'
import '../Styles/Loader.css'

export default function Loader() {
  return (
    <div className="load-row">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  )
}
