import React from 'react'
import '../Styles/Landing.css'
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { db } from '../Firebase.js';
import { Navigation, Socials } from './SmallUIComponents';
import { doc, getDoc } from 'firebase/firestore';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../Redux/Slices/userSlice';

export default function LandingPage(props) {

    const [opacity, setOpacity] = useState(1);
    const navigate = useNavigate()
    // const [UserDetails, setUserDetails] = useState(null)
    const [Loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const UserDetails = useSelector(state => state.UserDetails.UserDetails)

    useEffect(() => {

        const getDetails = async () => {
            setLoading(true)
            const docRef = doc(db, "user", "userDetails");
            await getDoc(docRef).then((res) => {
                // console.log(res.data());
                // setUserDetails(res.data());
                dispatch(setUser(res.data()))
                setLoading(false)
            }).catch((err) => {
                console.log(err.message);
            })
        }

        if(UserDetails.length === 0){
            getDetails()
        }
    }, [])

    useEffect(() => {
        function handleScroll() {
            const newOpacity = 1 - window.scrollY / window.innerHeight;
            setOpacity(newOpacity)
        }

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);


    return (
        <>
            <div className='landingPageContainer' style={{
                background: `url(${UserDetails?.CoverPic}) no-repeat center/cover`,
                opacity: opacity
            }}>


                <div className="ProfilePhoto">
                    <img src={`${UserDetails?.ProfilePic}`} alt="" />
                </div>

                {/* <h3>पवन सारस्वत मुकलावा</h3> */}
                <h3>{!Loading ? UserDetails.Name : "Loading..."}</h3>
                <p>{!Loading ? UserDetails.Email : "Loading"}</p>
                <p>{!Loading ? UserDetails.Phone : "Loading"}</p>
                {/* <p></p> */}
                <br />

                {/* <Socials /> */}
                {
                    props.isAdmin ? <Navigation />
                        : ''
                }

                <div className="overlay"></div>
            </div>

        </>
    )
}

