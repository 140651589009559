import React, { useState } from 'react'
import '../Styles/Gallery.css'
import { useLocation } from 'react-router'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'

export default function ImageViewer(props) {
    const location = useLocation()
    // checking user is logged in or not
    const accessToken = useSelector(state => state.Auth.Auth)
    const UserLoad = useSelector(state => state.Auth.Loading)
    const [IsAuth, setIsAuth] = useState(false)
    // console.log("from img component : "+location.state.image);

    useEffect(() => {
        if (accessToken !== null) {
            setIsAuth(true)
        }
    }, [UserLoad])
    return (
        <div className='onPhoto'>
            <img src={location.state.image} data-aos="zoom-in" data-aos-duration="200" className='image' alt="" />
            {/* {
                IsAuth ? <button>Delete</button> : ''
            } */}
        </div>
    )
}
