import { createSlice } from "@reduxjs/toolkit";

export const tokenStatus = Object.freeze({
    IDLE: 'idle',
    LOADING: 'loading',
    ERROR: 'error'
})

const authSlice = createSlice({
    name: "auth",
    initialState: {
        Auth: null,
        Loading: tokenStatus.LOADING
    },
    reducers: {
        updateAuth(state, action) {
            state.Auth = action.payload;
            return state;
        },
        updateTokenStatus(state, action) {
            state.Loading = action.payload
            return state
        }
    }
})
export const { updateAuth, updateTokenStatus } = authSlice.actions;
export default authSlice.reducer;