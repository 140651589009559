import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { doc, getDoc, increment, updateDoc, } from 'firebase/firestore';
import { db } from '../Firebase';
import { useState } from 'react';
import { useEffect } from 'react';
import LandingPage from '../SmallComponents/LandingPage';
import { Sharebutton } from '../SmallComponents/SmallUIComponents';
import Loader from '../SmallComponents/Loader';

import * as linkify from 'linkifyjs';
import linkifyHtml from 'linkify-html';
import Drawer from './Drawer';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveBlog } from '../Redux/Slices/activeBlogSlice';

import Cookies from 'js-cookie';


export default function QuestionBody(props) {
    const { qid } = useParams();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const clicked_question = useSelector(state => state.ActiveBlog.ActiveBlog)

    // const [clicked_question, setclicked_question] = useState([])
    const [Time, setTime] = useState()
    const [Date, setDate] = useState()
    const [Loading, setLoading] = useState(false)
    const linkRegex = /((http|https):\/\/[^\s]+)/g;

    useEffect(() => {
        const getData = async () => {
            setLoading(true)
            const docRef = doc(db, "root", qid);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                console.log("fetching data..");
                // setclicked_question(docSnap.data())
                dispatch(setActiveBlog(docSnap.data()))
                setLoading(false)
                document.getElementById("content").innerHTML = docSnap.data().content
            } else {
                alert('Blog Url is Invalid')
            }
        }

        // getting blog data by blog id
        // getData()
        if (clicked_question.length === 0) {
            getData()
        }
        else {
            document.getElementById("content").innerHTML = clicked_question.content
        }

    }, [])


    // whenever user click on share button
    function handleShareClick() {
        if (navigator.share) {
            navigator.share({
                title: "Pawan Sarswat Muklawa Blogs",
                text: "Check out this blog by Pawan Sarswat Muklawa",
                url: `${window.location.href}`,
            });
        } else {
            alert("Sharing is not supported on this device/browser.");
        }
    }


    // enlarging image when user clicks on a image
    const [data, setData] = useState('')
    const ViewImage = (img) => {
        setData(img)
        // console.log("here is the sending data : "+img);
    }
    useEffect(() => {
        if (data !== '') {
            navigate('/img', {
                state: {
                    image: data
                }
            })
        }
    }, [data])



    // Important peice of code for view count
    useEffect(() => {
        if (Cookies.get(`viewed_${qid}`) === undefined) {
            Cookies.set(`viewed_${qid}`, 'true', { expires: 365, path: `/blog/${qid}` });
            Inc()
        }
        else if (Cookies.get(`viewed_${qid}`) === 'true') {
            console.log("viewed");
        }
        else {
            console.log("Else condition");
        }
    }, [])

    const Inc = async () => {
        const docRef = doc(db, `views`, qid);
        await updateDoc(docRef, {
            'viewCount': increment(1)
        })
    }


    // function for fetching blog views
    const [BlogView, setBlogView] = useState('Loading ')
    const FetchViews = async () => {
        const view = await getDoc(doc(db, "views", qid))
        setBlogView(view.data().viewCount)
    }
    useEffect(() => {
        FetchViews()
    }, [])

    return (
        <>
            <LandingPage isBlog={true} />
            <div className="container base">
                {/* <p>{qid}</p> */}
                <div className="question-body">
                    {
                        Loading ? <Loader />
                            :
                            <div>
                                {/* all the details presented in the question body */}
                                <div className="question-title">
                                    {clicked_question.title}
                                </div>

                                <div className="timeDate">{clicked_question.createdAt?.toDate().toDateString()} | {clicked_question.createdAt?.toDate().toLocaleTimeString()}</div>

                                <div className="ViewCount">
                                    <i class="far fa-eye fa-sm"></i>
                                    {BlogView === "Loading " ? "Loading" : `${BlogView * 3} views`}
                                </div>

                                {/* image container , if any post contain any images. */}
                                {/* <div className="image-container">
                                    <img src={clicked_question.attachedImage} alt="" loading="lazy" />
                                </div> */}

                                <div className='qbody_images'>
                                    {clicked_question.attachedImage?.map((key) => (
                                        <img
                                            onClick={() => ViewImage(key.url)}
                                            src={key.url}
                                        />
                                    ))}
                                </div>


                                {/* question content */}
                                <div id='content' style={{ marginTop: '28px', fontSize: '16px', lineHeight: '30px' }}></div>
                                <Sharebutton Action={handleShareClick} />
                                <u><p onClick={() => { navigate('/') }}>Read more blogs...</p></u>
                            </div>
                    }
                </div>
                <Drawer />
            </div>
        </>
    )
}