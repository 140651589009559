import { createSlice } from "@reduxjs/toolkit";

const ActiveBlogSlice = createSlice({
    name: 'activeSlice',
    initialState: {
        ActiveBlog: []
    },
    reducers: {
        setActiveBlog(state, action) {
            state.ActiveBlog = action.payload
            return state
        }
    }
})

export const { setActiveBlog } = ActiveBlogSlice.actions;
export default ActiveBlogSlice.reducer;