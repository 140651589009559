import { createSlice, current } from "@reduxjs/toolkit";
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../Firebase';
import { query, orderBy, limit } from "firebase/firestore";

const status = Object.freeze({
    IDLE: 'idle',
    LOADING: 'loading',
    ERROR: 'error'
})

const BlogSlice = createSlice({
    name: "blog",
    initialState: {
        Loading: status.LOADING,
        FetchedBlogs: [],
        LastBlog: []
    },
    reducers: {
        toggleLoading(state, action) {
            state.Loading = action.payload;
            return state
        },
        setBlogs(state, action) {
            // state.FetchedBlogs = action.payload;
            state.FetchedBlogs = state.FetchedBlogs.concat(action.payload);
            return state
        },
        setLastblog(state, action) {
            state.LastBlog = action.payload;
            return state
        },
        deleteBlog(state, action) {
            const target = current(state).FetchedBlogs
            const res = target.filter((key) => key.BlogID !== action.payload)
            state.FetchedBlogs = res
            return state
        }
    }
})

export const { setBlogs, toggleLoading, setLastblog, deleteBlog } = BlogSlice.actions;
export default BlogSlice.reducer;



// async operations with redux thunk
export function GetBlogs() {
    return async function fetchBlogsWithReduxThunk(dispatch, getstate) {
        dispatch(toggleLoading(status.LOADING))
        let AllBlogs = [];
        const modifiedQuerry = query(collection(db, "root"), orderBy("createdAt", 'desc'), limit(2));
        const querySnapshot = await getDocs(modifiedQuerry);
        querySnapshot.forEach((doc) => {
            AllBlogs.push({
                BlogTitle: doc.data().title,
                BlogImage: doc.data().attachedImage,
                BlogContent: doc.data().content,
                createdAt: doc.data().createdAt,
                BlogID: doc.id,
                BlogViewCount: doc.data().viewCount
            })
        })
        // let orderedList = AllBlogs.sort((a, b) => a.createdAt.toMillis() - b.createdAt.toMillis()).reverse();
        dispatch(toggleLoading(status.IDLE))
        dispatch(setBlogs(AllBlogs))
        // Get the last visible document
        const last = querySnapshot.docs[querySnapshot.docs.length - 1];
        dispatch(setLastblog(last));
    }
}
