import { configureStore } from "@reduxjs/toolkit";
import BlogsSlice from "./Slices/BlogsSlice";
import authSlice from "./Slices/authSlice";
import Gallery from "./Slices/GallerySlice";
import activeBlogSlice from "./Slices/activeBlogSlice";
import userSlice from "./Slices/userSlice";

export const store = configureStore({
    reducer: {
        Blogs: BlogsSlice,
        Auth: authSlice,
        Gallery: Gallery,
        ActiveBlog: activeBlogSlice,
        UserDetails: userSlice
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})