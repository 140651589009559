// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getStorage } from "firebase/storage"
import { getAuth } from "firebase/auth";
import 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyByTZOFSzhvxERN1_QvcRoTRztZgQqTv1k",
  authDomain: "blog-d9cac.firebaseapp.com",
  projectId: "blog-d9cac",
  storageBucket: "blog-d9cac.appspot.com",
  messagingSenderId: "357114772841",
  appId: "1:357114772841:web:09df3f03269a7db70f91f8",
  measurementId: "G-X5S6SNCFDE"
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage();












