import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: 'user',
    initialState: {
        UserDetails: []
    },
    reducers: {
        setUser(state, action) {
            state.UserDetails = action.payload
            return state
        }
    }
})

export const { setUser } = userSlice.actions;
export default userSlice.reducer;