import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { UniversalLoader } from "./SmallUIComponents";

const ProtectedRoute = () => {
    const accessToken = useSelector(state => state.Auth.Auth)
    const UserLoad = useSelector(state => state.Auth.Loading)
    if (UserLoad !== 'loading') {
        if (accessToken !== null) {return <Outlet />;}
        else {return <Navigate to="/profile" />}
    }
    else{return <UniversalLoader/>}
};
export default ProtectedRoute;