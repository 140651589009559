import './App.css';
import AddBlog from './MajorComponents/AddBlog';
import Admin from './MajorComponents/Admin';
import Homepage from './MajorComponents/Homepage';
import Profile from './MajorComponents/Profile.js';
import QuestionBody from './MajorComponents/QuestionBody';
import { Route, BrowserRouter, Routes } from "react-router-dom";
import ProtectedRoute from './SmallComponents/ProtectedRoute';
import My404Component from './SmallComponents/My404Component';
import { auth } from './Firebase.js'
import { onAuthStateChanged } from 'firebase/auth';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { tokenStatus, updateAuth, updateTokenStatus } from './Redux/Slices/authSlice';
import { GetBlogs } from './Redux/Slices/BlogsSlice';
import Details from './SmallComponents/Details';
import Gallery from './MajorComponents/Gallery';
import ImageViewer from './SmallComponents/ImageViewer';
import Aos from "aos";
import "aos/dist/aos.css";
import Drawer from './MajorComponents/Drawer';
import About from './SmallComponents/About';

function App() {

  const dispatch = useDispatch()

  useEffect(() => {
    const get = () => {
      dispatch(GetBlogs())
    }
    get();
  }, [dispatch])

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);


  // const accessToken = useSelector(state => state.Auth.Auth)
  // const loading = useSelector(state => state.Auth.Loading)
  // const [isAuthenticated, setisAuthenticated] = useState(false)

  // useEffect(() => {
  //   if (accessToken !== null && accessToken.length > 10) {
  //     console.log("User is Authentiated ");
  //     setisAuthenticated(true)
  //   }
  // }, [loading])

  // console.log("hello " + loading, accessToken);


  // for remembering the logged user, if user open webapp in new browser window.
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      dispatch(updateAuth(currentuser ? currentuser.accessToken : null))
      dispatch(updateTokenStatus(tokenStatus.IDLE))
    });
    return () => {
      unsubscribe();
    };
  }, [dispatch]);


  return (
    <div className="App">
      <BrowserRouter>
        {/* <Drawer /> */}
        <Routes>
          <Route exact path="/" element={<Homepage />} />

          <Route exact path="/blog/:qid" element={<QuestionBody />} />

          <Route exact path="/profile" element={<Profile />} />

          <Route element={<ProtectedRoute />}>
            <Route exact path="/admin" element={<Admin />} />
            <Route exact path="/addblog" element={<AddBlog />} />
            <Route exact path="/details" element={<Details />} />
          </Route>

          <Route exact path="/gallery" element={<Gallery />} />
          <Route exact path="/img" element={<ImageViewer />} />
          <Route exact path="/about" element={<About />} />

          <Route path='*' element={<My404Component />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
