import { doc, getDoc } from 'firebase/firestore';
import { Markup } from 'interweave';
import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { db } from '../Firebase';

export default function QuestionTile(props) {
    const date = props.dateTime.toDate().toDateString()
    const time = props.dateTime.toDate().toLocaleTimeString()
    const navigate = useNavigate();

    function handleShareClick() {
        if (navigator.share) {
            navigator.share({
                title: "Pawan Sarswat Muklawa Blogs",
                text: "Check out this blog by Pawan Sarswat Muklawa",
                url: `${window.location.href}blog/${props.id}`,
            });
        } else {
            // Fallback for browsers that do not support Web Share API
            alert("Sharing is not supported on this device/browser.");
        }
    }

    // enlarging image when user clicks on a image
    const [data, setData] = useState('')
    const ViewImage = (img) => {
        setData(img)
        // console.log("here is the sending data : "+img);
    }
    useEffect(() => {
        if (data !== '') {
            navigate('/img', {
                state: {
                    image: data
                }
            })
        }
    }, [data])


    const [BlogView, setBlogView] = useState('Loading ')
    // function for fetching blog views
    const FetchViews = async () => {
        const view = await getDoc(doc(db, "views", props.id))
        setBlogView(view.data().viewCount)
    }
    useEffect(() => {
        FetchViews()
    }, [])

    return (
        <div className="question-tile">
            <div className="question-title"
            //  onClick={() => { navigate(`/blog/${props.id}`) }} target='_blank'
            >
                {/* title of the question/blog in the current tile */}
                {props.questionTitle}
            </div>

            <div className="timeDate">{date} | {time}</div>
            {/* <br /> */}

            <div className="ViewCount">
                <i class="far fa-eye fa-sm"></i>
                {BlogView === "Loading " ? "Loading" : `${BlogView * 3} views`}
            </div>
            <br />


            {/* image container , if any post contain any images. */}{
                props.image.length !== 0 ?
                    // <div className="image-container-question-tile">
                    //     <img src={props.image} alt="yo" loading="lazy" />
                    // </div> 
                    <div id="imgPreview">
                        {props.image.map((key) => (<img
                            src={key.url}
                            onClick={() => ViewImage(key.url)}
                        />))}
                    </div>
                    : ''
            }

            {/* sliced content */}{
                props.content !== "" ? <div id='blogContent' style={{ fontSize: '14px' }}>
                    {/* {`${props.content.substring(0, 500)}...`} */}
                    <Markup content={`${props.content.substring(0, 500)}`} />
                </div> : ''
            }
            <br />

            <div className="blogActions">
                <div onClick={() => {
                    // navigate(`/blog/${props.id}`)
                    window.open(`/blog/${props.id}`, "_blank", "noreferrer")
                }} target='_blank' className="openBlogButton">
                    <div>Read full blog</div>
                </div>
                <div className="shareButton" onClick={handleShareClick}>
                    <i className="fas fa-share"></i>
                </div>
            </div>

            <div className="line"></div>
        </div>
    )
}

// style={{ background: `url("${}") no-repeat center/cover` }}
